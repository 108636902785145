<template>
  <div
    class="modal fade"
    id="addAsset"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <form @submit.prevent="onSubmit">
            <div class="modal-header">
              <div class="modal-title text-center">{{ LabelModal }}</div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Kode</label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  v-model="formData.kode"
                  v-maska="'XXXX'"
                  :disabled="!isNew"
                  @change="checkKode"
                  placeholder="Masukan kode kategori"
                />
                <div class="form-error" v-if="msgExist">{{ msgExist }}</div>
              </div>
              <div class="form-group">
                <label>Nama Kategori : </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Masukkan Nama Kategori"
                  v-model="formData.nama"
                  required="true"
                />
              </div>
              <div class="form-group">
                <label>Status</label>
                <Select2
                  v-model="formData.status"
                  :options="statusAset"
                  placeholder="Status Aset"
                  @change="myChangeEvent($event)"
                  @select="mySelectEvent($event)"
                  required="true"
                />
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn btn-close" data-dismiss="modal">Batal</button> -->
              <button
                v-if="
                  checkAccess('asset') ||
                  checkAccess('asset', 'cru_category_asset')
                "
                type="submit"
                :disabled="isSubmit || isCheckCode"
                class="btn btn-save"
                sty
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link to="/data-asset" class="nav-link"
                  >Aset</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-asset-kategori" class="nav-link active"
                  >Kategori Aset
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/data-asset-check" class="nav-link"
                  >Pengecekan Aset</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-asset-selling" class="nav-link"
                  >Penjualan Aset</router-link
                >
              </li>
            </ul>
          </div>

          <!-- <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              id="searchbox"
              @keyup="searchCheck"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form> -->

          <button
            @click="addData"
            class="btn-add"
            v-if="
              checkAccess('asset') || checkAccess('asset', 'cru_category_asset')
            "
          >
            + Tambah
          </button>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableCategoryAset"
            >
              <thead>
                <tr>
                  <th style="width: 20%">Kode</th>
                  <th style="width: 55%">Nama Kategori</th>
                  <th style="width: 10%">Status</th>
                  <th style="width: 8%" class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in list" :key="index">
                  <td>{{ value.kode }}</td>
                  <td>{{ value.nama }}</td>
                  <td>
                    <div class="green-bedge" v-if="value.status == 'aktif'">
                      Aktif
                    </div>
                    <div class="red-bedge" v-else>Nonaktif</div>
                  </td>
                  <td class="text-center">
                    <svg
                      class="detail-icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                      v-on:click="detail(value.kode, value.nama, value.status)"
                      width="20"
                      height="20"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 25.8301H27.125"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.3125 4.52324C21.8264 4.00938 22.5233 3.7207 23.25 3.7207C23.6098 3.7207 23.9661 3.79158 24.2986 3.92928C24.631 4.06698 24.9331 4.26881 25.1875 4.52324C25.4419 4.77768 25.6438 5.07974 25.7815 5.41217C25.9192 5.74461 25.99 6.10091 25.99 6.46074C25.99 6.82057 25.9192 7.17687 25.7815 7.50931C25.6438 7.84175 25.4419 8.14381 25.1875 8.39824L9.04167 24.5441L3.875 25.8357L5.16667 20.6691L21.3125 4.52324Z"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { cksClient, showAlert, checkModuleAccess } from "../../../helper";
import {
  get_KategoriAsetCheckCode,
  get_KategoriAsetList,
  post_KategoriAsetSave,
} from "../../../actions/aset/kategori";
import { maska } from "maska";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      statusAset: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      dataTable: "",
      list: [],
      search: "",
      isSearch: "",
      id_company: cksClient().get("_account").id_company,
      isNew: false,
      isSubmit: true,
      isCheckCode: false,
      msgExist: "",
      formData: {
        id_company: cksClient().get("_account").id_company,
        kode: "",
        nama: "",
        status: "aktif",
      },
      LabelModal: "Tambah Kategori Aset",
    };
  },

  mounted() {
    this.tooltip();
  },

  created() {
    this.getList();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    addData() {
      this.formData.kode = this.generateString(3);
      this.isNew = true;
      this.isSubmit = false;
      this.isCheckCode = "";
      this.checkKode();
      this.LabelModal = "Tambah Kategori Aset";
      $("#addAsset").modal("show");
    },

    checkKode() {
      if (this.formData.kode) {
        this.isCheckCode = true;
        this.msgExist = "Tunggu sebentar....";
        get_KategoriAsetCheckCode(
          {
            id_company: this.id_company,
            kode: this.formData.kode,
          },
          (res) => {
            if (!res.is_exist) {
              this.isCheckCode = false;
              this.msgExist = "";
            } else {
              this.msgExist =
                "Kode kategori sudah terpakai, silakan gunakan kode yang lain";
            }
          },
          () => {
            this.msgExist = "Terjadi kesalahan, silakan ulangi kembali nanti";
          }
        );
      }
    },

    generateString(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(kode, nama, status) {
      this.formData.kode = kode;
      this.formData.nama = nama;
      this.formData.status = status;
      this.isSubmit = false;
      this.isNew = false;
      this.LabelModal = "Edit Kategori Aset";
      $('[data-toggle="tooltip"]').tooltip("hide");
      $("#addAsset").modal("show");
    },
    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    onSearch: function () {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    async getList() {
      await get_KategoriAsetList(
        {
          id_company: this.id_company,
          search: this.search,
        },
        (res) => {
          this.list = [];
          this.list = res.list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.tooltip();
            this.dataTable = $("#tableCategoryAset").DataTable({
              info: false,
              order: [[1, "asc"]],
            });
          }, 1000);
        },
        () => {
          this.list = [];
        }
      );
    },
    postData() {
      this.isSubmit = true;
      post_KategoriAsetSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          $("#addAsset").modal("hide");
          var msg =
            res.response_code == 201
              ? "Data kategori berhasil ditambahkan"
              : "Data kategori berhasil diperbarui";
          this.getList();
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            showCancelButton: false,
          });
        },
        () => {
          $("#addAsset").modal("hide");
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi Kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    onSubmit() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: this.formData.kode
          ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
          : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postData();
        },
      });
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
