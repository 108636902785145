import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_KategoriAsetList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.KATEGORI.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KategoriAsetSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.KATEGORI.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KategoriAsetCheckCode = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.KATEGORI.CHECK, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_KategoriAsetList,
  post_KategoriAsetSave,
  get_KategoriAsetCheckCode
}